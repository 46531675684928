body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.p-rating-icon {
  color: #ffc208 !important;
}


.p-accordion .p-accordion-content {
  padding: 0 !important;
}